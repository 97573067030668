.error {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.error__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 36px;
  line-height: 36px;
  font-family: $roboto;
  font-weight: 500;
  color: #111;

  @media(min-width: $tablet-width) {
    font-size: 60px;
    line-height: 60px;
  }
}

.error__link {
  position: absolute;
  top: 65%;
  left: 65%;
  transform: translate(-65%, -65%);

  font-size: 14px;
  line-height: 18px;
  font-family: $roboto;
  font-weight: 500;
  color: #111;
  
  @media(min-width: $tablet-width) {
    font-size: 16px;
    line-height: 24px;
  }
}