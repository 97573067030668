.faith {
  background-color: #F2F2F2;
}

.faith__wrapper {
  padding: 0 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin:0 auto;

  @media(min-width: $tablet-width) {
    padding-top: 50px;
  }

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.faith__title {
  font-size: 36px;
  line-height: 1;
  font-family: $roboto;
  font-weight: 500;
  color: #111;
  text-align: left;

  @media(min-width: $tablet-width) {
    font-size: 60px;
  }
}

.faith__text {
  margin-top: 33px;
  
  font-size: 14px;
  line-height: 1.3;
  font-family: $roboto;
  font-weight: 400;
  color: #444444;

  @media(min-width: $tablet-width) {
    margin-top: 60px;

    font-size: 16px;
    line-height: 1.5;
  }
}

.faith__text p {
  margin-top: 10px;
}

.faith__text p:first-child {
  @media(min-width: $tablet-width) {
    margin-top: 0;
  }
}