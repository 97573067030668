.info__wrapper {
  padding: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin: 0 auto;

  @media(min-width: $tablet-width) {
    padding-top: 50px;
  }

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
    padding-top: 80px;
  }
}

.info__title {
  font-size: 36px;
  line-height: 36px;
  font-family: $roboto;
  font-weight: 500;
  color: #111;
  text-align: left;

  @media(min-width: $tablet-width) {
    font-size: 60px;
    line-height: 60px;
  }
}

.info__contact-block {
  margin-top: 33px;

  font-size: 14px;
  line-height: 18px;
  font-family: $roboto;
  font-weight: 400;
  color: #111;
  text-align: left;

  @media(min-width: $tablet-width) {
    margin-top: 60px;

    font-size: 16px;
    line-height: 24px;
  }

  & p {
    margin-top: 10px;
  }

  & ul {
    list-style: inside;
  }
}

.info__link-contact {
  color: #111;
}

.info__donation-block {
  position: relative;
  margin-top: 33px;
  padding-top: 33px;

  font-size: 14px;
  line-height: 18px;
  font-family: $roboto;
  font-weight: 400;
  color: #111;
  text-align: left;

  @media(min-width: $tablet-width) {
    margin-top: 60px;
    padding-top: 60px;

    font-size: 16px;
    line-height: 24px;
  }

  & p {
    margin-top: 10px;
  }

  & ul {
    margin-top: 10px;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #EEEEEE;
  }
}