.button {
  position: relative;
  padding: 16px 39px 16px 24px;
  width: 130px;

  font-size: 14px;
  line-height: 14px;
  font-family: $roboto;
  color: #ffffff;
  font-weight: 500;

  border: none;
  background-color: #39B76B;
  border-radius: 5px;
  text-transform: uppercase;

  &::after {
    position: absolute;
    content: "";
    top: 15px;
    right: 15px;
    
    width: 15px;
    height: 15px;
    background-image: url("../img/menu-icon.svg");
    background-size: 15px 15px;
  }

  &:hover {
    background-color: lighten(#39B76B, 10%);
  }

  @media(min-width: $tablet-width) {
    display: none;
  }
}
