.rules__wrapper {
  padding: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin: 0 auto;

  @media(min-width: $tablet-width) {
    padding-top: 50px;
  }

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
    padding-top: 80px;
  }
}

.rules__title {
  font-size: 36px;
  line-height: 36px;
  font-family: $roboto;
  font-weight: 500;
  color: #111;
  text-align: left;

  @media(min-width: $tablet-width) {
    font-size: 60px;
    line-height: 60px;
  }
}

.rules__swiper-container {
  margin-top: 35px;

  @media(min-width: $tablet-width) {
    margin-top: 60px;
  }
}

.rules__text {
  position: relative;

  padding-left: 130px;
  min-height: 250px;

  font-size: 14px;
  line-height: 18px;
  font-family: $roboto;
  font-weight: 400;
  color: #111;
  text-align: left;
  
  @media(min-width: $tablet-width) {
    min-height: 280px;

    font-size: 16px;
    line-height: 24px;
  }

  &::before{
    position: absolute;
    content: attr(data-number);
    top: 66px;
    left: 0;
    z-index: -1;

    font-size: 200px;
    font-family: $roboto;
    font-weight: 500;
    color: #F2F2F2;
  }
}
