.schedule__wrapper {
  padding: 0 20px;
  margin: 0 auto;
  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
  }
}

.schedule__inner-conteiner {
  position: relative;

  padding: 20px;
  height: 100%;
  background-color:#009699;

  border: 1px solid #fff;
  @media(min-width: 500px) {
    padding: 20px;
  }

  @media(min-width: $tablet-width) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px 20px;
  }

  &::after{
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    border: 1px solid #fff;
  }
}

.schedule__caption {
  font-size: 16px;
  line-height: 18px;
  font-family: $roboto;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;

  @media(min-width: 500px) {
    font-size: 18px;
  }

  @media(min-width: $desktop-width) {
    font-size: 24px;
    line-height: 24px;
  }
}

.schedule__item {
  margin-top: 10px;

  font-size: 14px;
  line-height: 16px;
  font-family: $roboto;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;

  @media(min-width: 500px) {
    margin-top: 20px;

    font-size: 16px;
  }

  @media(min-width: $desktop-width) {
    font-size: 18px;
    line-height: 18px;
  }

  &:first-child {
    @media(min-width: $tablet-width) {
      margin-top: 0;
    }
  }
}