.article__header {
  position: relative;

  height: 305px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media(min-width: $tablet-width) {
    height: 372px;
  }

  @media(min-width: $desktop-width) {
    height: 500px;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: #435061;
    opacity: 0.5;
    z-index: 1;
  }
}

.article__header-wrapper {
  padding: 0 20px;
  margin: 0 auto;
  height: inherit;

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
  }
}

.article__title {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  font-size: 24px;
  line-height: 1.2;
  font-family: $roboto;
  font-weight: 500;
  color: #fff;

  text-transform: uppercase;
  text-align: center;

  @media(min-width: $tablet-width) {
    font-size: 36px;
  }
}

.article__content-container {
  font-size: 14px;
  line-height: 1.3;
  font-family: $mont;
  font-weight: 400;
  color: #444444;

  @media(min-width: $tablet-width) {
    font-size: 16px;
    line-height: 1.5;
  }

  @media(min-width: $desktop-width) {
    font-size: 18px;
  }

  p:not(:last-child) {
    margin-bottom: 10px;
  }

  h2 {
    font-size: 24px;
    line-height: 1.54;
    font-family: $roboto;
    font-weight: 400;
    color: #444444;
    text-transform: uppercase;
    text-align: center;

    @media(min-width: $tablet-width) {
      font-size: 26px;
    }

    @media(min-width: $desktop-width) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 1.54;
    font-family: $roboto;
    font-weight: 400;
    color: #444444;
    text-transform: uppercase;
    text-align: center;

    @media(min-width: $tablet-width) {
      font-size: 20px;
    }

    @media(min-width: $desktop-width) {
      font-size: 22px;
    }
  }

  ul {
    list-style: inside;
  }
  
  figure {
    display: none;
    text-align: center;
    @media(min-width: $tablet-width) {
      display: block;
    }
  }

  img {
    max-width: 50%;
  }
}

.article__content-wrapper {
  padding: 20px 20px;
  margin: 0 auto;

  @media(min-width: $tablet-width) {
    max-width: $tablet-width;
  }
  
  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
  }
}