.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu__list {
  text-align: center;

  &--scrolled {
    max-height: 0;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  @media(min-width: $tablet-width) {
    max-height: none;
    height: 108px;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menu__item {
  margin-top: 20px;

  @media(min-width: $tablet-width) {
    margin: 0;
    margin-left: 30px;
  }

  &--submenu {
    @media(min-width: $tablet-width) {
      position: relative;
      margin-right: 15px;
    }
  }

}

//submenu
.menu__list-submenu {
  max-height: 0;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  @media(min-width: $tablet-width) {
    position: absolute;
    top: -1000px;
    left: 0;
    width: 150%;
    max-height: none !important; //чтобы перезаписать свойство которое вычисляется через js
    overflow: visible;
    z-index: 2;

    padding-left: 10px;
    padding-bottom: 20px;

    text-align: left;

    background-color: #ffffff;
    border: 1px solid #8E8E8E;
    border-radius: 2px;
  }

  &--open {
    @media(min-width: $tablet-width) {
      top: 20px;
    }
  }
}

.menu__item-submenu {
  margin-top: 20px;
}

.menu__link {
  font-size: 18px;
  line-height: 18px;
  font-family: $mont;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
  transition: color .3s ease;

  &--inserted {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 3px;
      right: -15px;

      width: 10px;
      height: 10px;
      background-image: url("../img/arrow-link-icon.svg");
      background-size: 10px 10px;
      transform: rotate(180deg);
    }
  }

  &:hover{
    color: #7C7C7D;
  }
}
