.header {
  @media(min-width: $desktop-width) {
    border-bottom: 1px solid #EEEEEE;
  }
}

.header__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 10px;
  background-color: #FFFFFF;

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
    flex-direction: row;
    padding-top: 0;
  }
}

.header__logo-link {
  @media(min-width: $desktop-width) {
    display: block;
    width: 300px;
  }
  &--text {
    width: 400px;
    display: flex;
    flex-direction: column;

    font-family: $roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #222;

    @media(max-width: $tablet-width) {
      width: auto;
      font-size: 14px;
      margin: 0 auto;
      text-align: center;
    }
  }
  &--text span:last-child {
    text-transform: uppercase;
    width: 100%;
  }
}

.header__menu {
  padding: 30px 0;
  width: 100%;
  margin-top: 10px;

  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;

  @media(min-width: $tablet-width) {
    padding: 0;
  }

  @media(min-width: $desktop-width) {
    margin-top: 0;
    width: auto;
    border:none;
    margin-left: auto;
    margin-right: 20px;
  }
}