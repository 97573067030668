.footer {
  background-color: #435061;
}

.footer__wrapper {
  padding: 0 20px;
  padding-bottom: 20px;
  padding-top: 39px;
  margin: 0 auto;

  @media(min-width: $tablet-width) {
    display: flex;
    flex-wrap: wrap;
  }

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
  }
}

.footer__title-block {
  @media(min-width: $tablet-width) {
    width: 100%;
  }
  @media(min-width: $desktop-width) {
    width: calc(100% / 4);
  }
}

.footer__first-name {
  display: block;

  font-size: 14px;
  line-height: 1.43;
  font-family: $roboto;
  font-weight: 400;
  color: #fff;

  @media(min-width: $tablet-width) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.footer__second-name {
  display: block;

  font-size: 16px;
  line-height: 1.25;
  font-family: $roboto;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;

  @media(min-width: $tablet-width) {
    font-size: 20px;
  }
}

.footer__contact-block {
  margin-top: 39px;

  @media(min-width: $tablet-width) {
    width: calc(100% / 3);
  }
  @media(min-width: $desktop-width) {
    width: calc(100% / 4);
    margin-top: 0;
    padding-left: 20px;
  }
}

.footer__block-title {
  font-size: 16px;
  line-height: 1.25;
  font-family: $roboto;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;

  @media(min-width: $tablet-width) {
    margin-bottom: 10px;
  } 
}

.footer__address,
.footer__link-contact {
  display: block;
  margin-top: 5px;

  font-size: 14px;
  line-height: 1.43;
  font-family: $roboto;
  font-weight: 400;
  color: #fff;
  font-style: normal;

  @media(min-width: $tablet-width) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.footer__usefullink-block {
  margin-top: 39px;

  @media(min-width: $tablet-width) {
    width: calc(100% / 3);
    padding-left: 20px;
  }
  @media(min-width: $desktop-width) {
    width: calc(100% / 4);
    margin-top: 0;
  }
}

.footer__link {
  display: block;
  margin-top: 5px;

  font-size: 14px;
  line-height: 1.43;
  font-family: $roboto;
  font-weight: 400;
  color: #fff;
  font-style: normal;

  @media(min-width: $tablet-width) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.footer__confidential-block {
  margin-top: 39px;

  @media(min-width: $tablet-width) {
    width: calc(100% / 3);
    padding-left: 20px;
  }
  @media(min-width: $desktop-width) {
    width: calc(100% / 4);
    margin-top: 0;
  }
}

.footer__copyright {
  margin-top: 39px;

  font-size: 11px;
  line-height: 1.43;
  font-family: $roboto;
  font-weight: 400;
  color: #fff;

  @media(min-width: $tablet-width) {
    width: 100%;

    text-align: center;
  } 
}