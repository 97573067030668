*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

ul {
  list-style: none;
}

ul, h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}