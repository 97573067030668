.invitation--contact-page {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;

    background-color: #EEEEEE;

    @media(min-width: $desktop-width) {
      display: none;
    }
  }
}

.invitation__wrapper {
  @media(min-width: $desktop-width) {
    position: relative;

    max-width: $desktop-width;
    margin:0 auto;
    max-width: $desktop-width;
  }
}

.invitation__text-block {
  padding: 32px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(min-width: $tablet-width) {
    padding: 69px 72px;
  }

  @media(min-width: $desktop-width) {
    position: absolute;
    top: 95px;
    right: 30px;
    z-index: 1;

    width: 570px;
    height: 200px;

    background-color: #FFFFFF;
  }
}

.invitation__title {
  width: 134px;

  font-size: 16px;
  line-height: 1.25;
  font-family: $roboto;
  font-weight: 500;
  text-transform: uppercase;
  color: #111;

  @media(min-width: $tablet-width) {
    width: 154px;

    font-size: 20px;
  }
}

.invitation__address {
  display: flex;
  flex-direction: column;
  width: 134px;

  font-size: 14px;
  line-height: 1.43;
  font-family: $roboto;
  font-weight: 400;
  font-style: normal;
  color: #444444;

  @media(min-width: $tablet-width) {
    width: 153px;

    font-size: 16px;
    line-height: 1.25;
    text-align: right;
  }
}

.invitation__city {
  @media(min-width: $tablet-width) {
    margin-top: 15px;
  }
}
