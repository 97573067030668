.about-us {
  border-bottom: 1px solid #EEEEEE;
}

.about-us__wrapper {
  padding: 32px 20px;

  @media(min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media(min-width: $tablet-width) {
    padding-top: 50px;
  }

  @media(min-width: $desktop-width) {
    padding: 48px 20px;
    max-width: $desktop-width;
    margin: 0 auto;
  }
}

.about-us__title {
  width: 100%;

  font-size: 36px;
  line-height: 1.67;
  font-family: $roboto;
  font-weight: 500;
  color: #111111;

  @media(min-width: $tablet-width) {
    margin-bottom: 40px;

    font-size: 60px;
    line-height: 1;
  }
}

.about-us__article {
  position: relative;

  margin-top: 25px;
  width: 100%;
  height: 175px;

  overflow:hidden;
  transition: all .3s ease;
  
  @media(min-width: 600px) {
    width: 48%;
  }

  @media(min-width: $tablet-width) {
    height: 250px;
    width: 49%;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: 2;

    width: 100%;
    height: 100%;

    background-color: #435061;
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.01);
  }

  &:hover .about-us__article-title::before {
    width: 100%;
  }

  &:hover .about-us__article-title::after {
    width: 100%;
  }
}

.about-us__link {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.about-us__image {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 1;

 width: 100%;
 height: 100%;

 object-fit: cover;
 
 &--bottom {
  object-position: left bottom;
 }
}

.about-us__article-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;

  width: 80%;

  font-size: 20px;
  line-height: 1.2;
  font-family: $mont;
  font-weight: 500;
  color: #fff;

  text-align: center;

  @media(min-width: $desktop-width) {
    font-size: 22px;
  }

  &::before{
    position: absolute;
    content: "";
    top: -10px;
    left: 0;

    width: 0%;
    height: 1px;

    background-color: #fff;
    transition: width .3s ease;
  }

  &::after{
    position: absolute;
    content: "";
    bottom: -10px;
    right: 0;

    width: 0%;
    height: 1px;

    background-color: #fff;
    transition: width .3s ease;
  }
}
