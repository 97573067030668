@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
}