.slider__wrapper {
  padding: 20px;
  margin: 0 auto;

  @media(min-width: $tablet-width) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
  }
}

.slider-image {
  max-width: 100%;
  height: 100%;

  @media(min-width: $tablet-width) {
    max-width: 80%;
  }
}