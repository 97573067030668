.library__wrapper {
  padding: 32px 20px;
  margin: 0 auto;
  height: inherit;

  @media(min-width: $tablet-width) {
    padding-top: 50px;
  }

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
    padding-top: 80px;
  }
}

.library__article {
  position: relative;

  padding-bottom: 30px;
  transition: padding .3s ease;

  & p, .library__link {
    font-size: 16px;
    line-height: 1.5;
    font-family: $mont;
    font-weight: 400;
    color: #111;

    @media(min-width: $desktop-width) {
      font-size: 18px;
    }
  }

  .library__link {
    display: block;
    margin-top: 15px;

    color: blue;
  }

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background-color: #EEEEEE;
  }

  &:hover {
    padding-left: 20px;
  }
}

.library__title {
  font-size: 36px;
  line-height: 36px;
  font-family: $roboto;
  font-weight: 500;
  color: #111;
  text-align: left;

  @media(min-width: $tablet-width) {
    font-size: 60px;
    line-height: 60px;
  }
}

.library__h2-title {
  margin: 30px 0;

  font-size: 20px;
  line-height: 1.5;
  font-family: $roboto;
  font-weight: 500;
  color: #111;

  text-transform: uppercase;

  @media(min-width: $tablet-width) {
    font-size: 25px;
  }
}