.category__wrapper {
  padding: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin: 0 auto;

  @media(min-width: $tablet-width) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media(min-width: $desktop-width) {
    max-width: $desktop-width;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.category__title {
  font-size: 36px;
  line-height: 36px;
  font-family: $roboto;
  font-weight: 500;
  color: #111;
  text-align: left;

  @media(min-width: $tablet-width) {
    font-size: 60px;
    line-height: 60px;
  }
}

.category__article {
  margin-top: 35px;

  @media(min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

}

.category__link-image {
  @media(min-width: $tablet-width) {
    display: block;
    width: 357px;
    height: 238px;
  }
}

.category__link-title {
  &:hover .category__article-title::after{
    width: 100%;
  }
}

.category__image {
  transition: all .3s ease;

  @media(min-width: $tablet-width) {
    width: 100%;
    height: 100%;
  }

  &:hover {
    transform: scale(1.02);
  }
}

.category__article-block {
  @media(min-width: $tablet-width) {
    width: calc(100% - 357px - 20px);
  }
}

.category__article-title {
  position: relative;

  display: inline-block;
  margin: 10px 0;

  font-size: 24px;
  line-height: 1.25;
  font-family: $roboto;
  font-weight: 400;
  color: #111111;
  text-transform: uppercase;

  @media(min-width: $tablet-width) {
    margin: 0;
    margin-bottom: 10px;

    font-size: 30px;
  }

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;

    width: 0%;
    height: 1px;
    background-color: #39B76B;

    transition: width .3s ease;
  }

}

.category__description-block {
  font-size: 14px;
  line-height: 18px;
  font-family: $roboto;
  font-weight: 400;
  color: #111;
  text-align: left;

  @media(min-width: $tablet-width) {
    font-size: 16px;
    line-height: 24px;
  }
}

.category__pagination {
  margin-top: 35px;
}

.category__list {
  display: flex;
  justify-content: center;
}

.category__item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  font-size: 18px;
  line-height: 1;
  font-family: $roboto;
  font-weight: 400;
  color: #111;
  text-align: center;

  & a {
    color: inherit;
  }
}

.category__item:not(:last-child) {
  margin-right: 30px;
}

.category__active {
  font-weight: 500;
  font-size: 24px;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 1px solid black;
    border-radius: 50%;
  }
}